<template>
  <v-container
    fluid
    class="pa-0"
  >
    <div
      class="section1 small"
    >
      <v-row>
        <v-col
          cols="12"
          sm="8"
          md="6"
          lg="4"
          class="mt-16"
        >
          <v-spacer></v-spacer>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="ml-16 mt-16">
          <div class="pet-title">
            Who should buy <br> pet insurance?
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="section2">
      <v-row>
        <v-col class="mt-16">
          <v-spacer class="mt-16"></v-spacer>
        </v-col>
      </v-row>
    </div>
    <div class="section3">
      <v-row>
        <v-col cols="6">
          <div class="insurance mt-10 ml-16">
            Pet Insurance
          </div>
        </v-col>
      </v-row>
      <v-row class="mb-16">
        <v-col cols="6">
          <div class="sec3-sub mt-10 ml-16">
            Trupanion is the official Blanket partner for pet insurance. Trupanion was selected for many reasons.
          </div>
        </v-col>
        <v-col
          cols="5"
          class="d-flex justify-end mt-n16"
        >
          <v-card class="ins-card pt-8 mb-16 px-4">
            <v-row justify="center">
              <v-col
                cols="9"
                class="d-flex justify-center"
              >
                <v-img
                  src="/img/trupanion-logo.png"
                ></v-img>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col class="d-flex justify-center">
                <v-btn
                  class="white learn font-weight-bold ml-6"
                  large
                  href="https://trupanion.com/"
                  target="_blank"
                >
                  <span class="secondary--text">Learn More</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div class="section4 mt-n16 pb-16">
      <v-row>
        <v-col>
          <v-img
            src="/img/pets-hang.png"
            class="mt-16"
          ></v-img>
        </v-col>
      </v-row>
      <v-row class="mt-n8">
        <v-col>
          <div class="sec4-txt">
            This policy is designed with your pet in mind
          </div>
        </v-col>
      </v-row>
      <v-row
        justify="center"
        class="mt-n2"
      >
        <v-col
          cols="11"
        >
          <v-row justify="center mt-16 mb-0">
            <v-col
              cols="4"
              class="d-flex justify-center mt-16"
            >
              <v-img
                src="/img/simple-plan.png"
                height="101px"
                max-width="132px"
              ></v-img>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center mt-16"
            >
              <v-img
                src="/img/90-coverage.png"
                height="109px"
                max-width="120px"
              ></v-img>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center mt-16"
            >
              <v-img
                src="/img/payout-limit.png"
                height="108px"
                max-width="133px"
              ></v-img>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col
              cols="4"
              class="d-flex justify-center"
            >
              <div class="sec4-subtxt">
                One Simple Plan
              </div>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center"
            >
              <div class="sec4-subtxt">
                90% Coverage
              </div>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center"
            >
              <div class="sec4-subtxt">
                No Payout Limit
              </div>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col
              cols="4"
              class="d-flex justify-center"
            >
              <div class="sec4-info">
                All pets get the best coverage we have to offer under our one simple plan.
              </div>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center"
            >
              <div class="sec4-info">
                The plan covers 90% of actual veterinary costs for eligible claims once you meet the deductible.
              </div>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center"
            >
              <div class="sec4-info">
                Your pet receives unlimited lifetime insurance coverage without caps.
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        justify="center"
        class="mt-n2"
      >
        <v-col
          cols="11"
        >
          <v-row justify="center mt-16 mb-0">
            <v-col
              cols="4"
              class="d-flex justify-center mt-16"
            >
              <v-img
                src="/img/vet-direct.png"
                height="119px"
                max-width="116px"
              ></v-img>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center mt-16"
            >
              <v-img
                src="/img/customer-care.png"
                height="119px"
                max-width="136px"
              ></v-img>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center mt-16"
            >
              <v-img
                src="/img/compare-us.png"
                height="122px"
                max-width="123px"
              ></v-img>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col
              cols="4"
              class="d-flex justify-center"
            >
              <div class="sec4-subtxt">
                Vet Direct Pay
              </div>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center"
            >
              <div class="sec4-subtxt">
                24/7 Customer Care
              </div>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center"
            >
              <div class="sec4-subtxt">
                Compare Us
              </div>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col
              cols="4"
              class="d-flex justify-center"
            >
              <div class="sec4-info">
                Since their start, the Trupanion Policy has paid out over $1 billion to help cats and dogs get the medical care they need. They don't cap coverage and have always offered to pay 90% of eligible claims – that’s just a part of our high-quality policy.
              </div>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center"
            >
              <div class="sec4-info">
                Trupanion understands that protecting your pet isn't a 9 to 5 job. Rest easy knowing that no matter when your pet needs help, we'll be here to help you through it.
              </div>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center"
            >
              <div class="sec4-info">
                We encourage you to research other pet insurance companies.
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="section5">
    </div>
  </v-container>
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>
.section1{
  position: relative;
  width: 100%;
  background: url("../../../../public/img/pet-learnmore-background.png") no-repeat;
  background-size: cover;
}

.section2 {
    background-color: #FFFFFF;
}

.section3 {
    background-color: #DEECF1;
}

.section4 {
    background-color: #FFFFFF;
}

.sec3-sub {
font-size: 28px;
line-height: 132.19%;
color: #757575;
}

.pet-title {
font-style: normal;
font-weight: normal;
font-size: 60px;
line-height: 70px;
letter-spacing: -0.005em;

color: #616161;
}

.insurance {
font-size: 60px;
line-height: 70px;
letter-spacing: -0.005em;
color: #757575;
}

.ins-card {
    background-color: #EEEEEE;
    width: 425px;
    height: 252px;
}

.learn {
  border: 1px solid #00A1B7 !important;
}

.sec4-txt {
    font-size: 55px;
line-height: 64px;
text-align: center;
letter-spacing: -0.005em;
color: #757575;
}

.plan-img {
    max-width: 155px;
    height: auto;
    object-fit: scale-down;
}

.coverage-img {
    max-width: 130px;
    height: auto;
    object-fit: scale-down;
}

.payout-img {
    max-width: 145px;
    height: auto;
    object-fit: scale-down;
}

.customercare-img {
    max-width: 136px;
    height: 119px;
    object-fit: scale-down;
}
.sec4-subtxt {
font-weight: 500;
font-size: 24px;
line-height: 32px;
text-align: center;
text-transform: capitalize;
color: #212121;
}

.sec4-info {
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    font-feature-settings: 'liga' off;
    color: #757575;
}

.section5 {
    height: 500px;
    background: rgba(216, 253, 215, 0.5);
}

.small {
    height: 700px;
}

.large {
    height: 900px;
}
</style>
